import React from 'react';
import { toast } from 'react-toastify';

const toastify = (toastType, defaultStyles) => (message, options = {}) =>
  toastType(message, { ...options, ...defaultStyles });

// const info = toastify(toast.info, {});
const buildErrorToast = toastify(toast.error, {});
const buildSuccessToast = toastify(toast.success, {});

const buildDefaultMessageBody = (type, message) => (
  <div className="toast-content">
    <p className="toast-title">{type}</p>
    <p className="toast-message">{message}</p>
  </div>
);

const MESSAGE_TYPES = {
  ERROR: 'Error',
  SUCCESS: 'Success',
};

const buildSuccessBody = (message, hasIcon = true) => {
  return (
    <div className="toast-body">
      {hasIcon && <div className="toast-success-icon" />}
      {buildDefaultMessageBody(MESSAGE_TYPES.SUCCESS, message)}
    </div>
  );
};

const buildErrorBody = (message, hasIcon = true) => {
  return (
    <div className="toast-body">
      {hasIcon && <div className="toast-error-icon" />}
      {buildDefaultMessageBody(MESSAGE_TYPES.ERROR, message)}
    </div>
  );
};

export const showErrorToast = (message, options) => buildErrorToast(buildErrorBody(message), options);
export const showSuccessToast = (message, options) => buildSuccessToast(buildSuccessBody(message), options);

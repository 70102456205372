import React, { useContext, createContext } from 'react';

import { showSuccessToast, showErrorToast } from '../../utils/notification';

const Context = createContext({});

// todo: modify to fit use case
const getResponseMessage = response => {
  // v2 api
  if (response && response.data) {
    const { message } = response.data;

    return message;
  }

  // v1 api
  if (response.message) {
    return response.message;
  }

  // api v1 inconsistent
  if (response.errors && response.errors.length) {
    const { message } = response.errors[0];

    return message;
  }

  // todo: move to enum
  return 'Something went wrong';
};

export const NotificationStore = props => {
  const showSuccess = message => showSuccessToast(message);
  const showError = async response => {
    const body = await response.json();

    showErrorToast(getResponseMessage(body));
  };

  const values = {
    showError,
    showSuccess,
  };

  return <Context.Provider value={values} {...props} />;
};

export const useNotificationStore = () => useContext(Context);
